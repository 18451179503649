import { Injectable } from '@angular/core';

import { classToPlain, plainToClass } from 'class-transformer';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, switchMap, } from 'rxjs/operators';


import { ConfigService, DownloadService, RestService, UploadService, } from '@saliente/common';
import { AuthService, } from '@saliente/common-auth';
import { SpinnerService, dateToInt, intToDate } from '@saliente/library';

import { DocumentFileStatus, DocumentPageModel, DocumentsService, FinancialDocumentEntryType, PersonForAccountingModel } from '@saliente/common-documents';

import { AccDocumentFileModel } from './documents.models';
import { IClientDocumentsService } from './documents.service';


const documentPartialQueryText = `
	eid
	clientEid
	costCenter
	documentDate
	status { statusCode, dateTime }
	metadata
	pages { pageEid, visible }
	totalPages
	comments{ dateTime, comment }
	created { dateTime, userEid, userName }
`;

// old query: see below the new one
/* const clientDocumentsQueryText = gql`
query clientDocumentsData($clientEid: String!, $filter: String!, $sort: String, $offset: Int, $count: Int){
	documents{
	  GetClientDocumentsUsingGET(clientEid:$clientEid, filter:$filter, sort:$sort, offset:$offset, count:$count){
		${documentPartialQueryText}
	  }
	  GetClientDocumentsStatisticsUsingGET(clientEid:$clientEid){
		chargeableAmount
		chargeableInterval
	  }
	}
}
`;*/
const clientDocumentsQueryText = gql`
query clientDocumentsData($clientEid: String!, $filter: String!, $sort: String, $offset: Int, $count: Int) {
	documents {
	  GetClientDocumentsExUsingGET(clientEid: $clientEid, filter: $filter, sort: $sort, offset: $offset, count: $count) {
		documents {
			${documentPartialQueryText}
		}
		counters {
			chargeableAmount
			chargeableInterval
		}
	  }
	}
}
`;


const setCostCenterMutationText = gql`
mutation setCostCenter($docEid: String!, $costCenter: documents_DocStructurePatchDataInput){
  documents {
    UpdateDocumentUsingPUT(docEid:$docEid, data: $costCenter) {
		Message
    }
  }
}`;

const setEmployeeMutationText = gql`
mutation setEmployee($docEid: String!, $data: documents_DocStructurePatchDataInput){
  documents {
    UpdateDocumentUsingPUT(docEid: $docEid, data: $data) {
		Message
    }
  }
}`;

const documentQueryText = gql`
query documentData($docEid: String!) {
  documents {
    GetDocumentUsingGET(docEid: $docEid) {
	  ${documentPartialQueryText}
    }
  }
}
`;

const accMetadataQueryFields = `
	currency
	documentDate
	documentNumber
	dueDate
	entryTypeName
	externalId
	postings {
		debitAccount
		creditAccount
		amount
		currencyAmount
		employeeName
		employeePIN
		partnerName
	}
`;
const accMetadataQueryText = gql`
query accMetadata($docEid: String!) {
  subs{
    GetFinancialDataUsingGET(docEid:$docEid){
		${accMetadataQueryFields}
    }
  }
}
`;

const accFullMetadataQueryText = gql`
query accMetadata($docEid: String!) {
  subs{
    GetFullFinancialDataUsingGET(docEid:$docEid){
		${accMetadataQueryFields}
    }
  }
}
`;

const updateDueDateMutationText = gql`
mutation updateDueDate($documentEid: String!, $dueDate: Int!){
	documents{
		UpdateDocumentDueDateUsingPOST(documentExternalId: $documentEid, dueDate: $dueDate){
			Message
		}
	}
}`;

@Injectable()
export class ClientAccDocumentsService extends DocumentsService<AccDocumentFileModel, DocumentPageModel> implements IClientDocumentsService {

	public filterTypes: any[] = [
		{ type: 'keyword', text: 'Cuvânt cheie' },
		{ type: 'value', text: 'Valoare document' },
		{ type: 'date', text: 'Dată document' },
		{ type: 'reference', text: 'Referință' },
	];

	constructor(configService: ConfigService, restService: RestService, authService: AuthService,
		downloadService: DownloadService, uploadService: UploadService, spinner: SpinnerService,) {

		super(configService, restService, authService, downloadService, uploadService, spinner);
		this.statistics = {};
	}

	protected getDocumentsCategory(): string {
		return 'Documents';
	}

	protected receivedStatusName(plural: boolean = false): string {
		return plural ? 'Necontate' : 'Necontat';
	}

	protected getDocumentsQueryText() {
		return clientDocumentsQueryText;
	}

	protected getDocumentsQueryResponse(response: any) {
		return response.data.documents.GetClientDocumentsExUsingGET.documents;
	}

	protected getDocumentQueryText() {
		return documentQueryText;
	}

	protected getDocumentQueryResponse(response: any) {
		return response.data.documents.GetDocumentUsingGET;
	}

	protected plainToDocument(data: any): AccDocumentFileModel {
		return plainToClass(AccDocumentFileModel, data);
	}

	protected plainToDocumentArray(data: any[]): AccDocumentFileModel[] {
		return plainToClass(AccDocumentFileModel, data);
	}

	public documentsQuery(variables: { companyId: string, offset?: number, filter?: string, sortBy?: string }): Observable<AccDocumentFileModel[]> {
		let filter = 'category:' + this.getDocumentsCategory();
		if (variables.filter) {
			filter += ' AND ' + variables.filter;
		}
		return this.restService
			.query(
				{
					query: this.getDocumentsQueryText() as any,
					fetchPolicy: 'network-only',
					variables: {
						clientEid: variables.companyId,
						filter: filter,
						sort: variables.sortBy,
						offset: variables.offset || 0,
						count: this.pageSize
					}
				})
			.pipe(
				map((response: any) => {
					if (response && response.data) {
						const statistics = response.data.documents.GetClientDocumentsExUsingGET.counters;
						if (statistics && statistics.length) {
							statistics.forEach((s: any) => {
								this.statistics[s.chargeableInterval] = s.chargeableAmount;
							});
						}

						this._currentListModel = this.plainToDocumentArray(this.processDocuments(this.getDocumentsQueryResponse(response)));
						return this.plainToDocumentArray(this.processDocuments(this.getDocumentsQueryResponse(response)));
					}
					return null;
				})
			);
	}

	public accMetadataStream(file: AccDocumentFileModel, queryText: string): Observable<any> {
		if (file.status.statusCode == DocumentFileStatus.Booked) {
			return this.restService
				.query(
					{
						query: queryText as any,
						variables: {
							docEid: file.id
						},
						fetchPolicy: 'network-only'
					}, { spinner: false, error: false })
				.pipe(
					map((response: any) => {
						if (response) {
							response = response.data.subs.GetFinancialDataUsingGET;
							if (response.length > 0) {
								return response.map((finData: any) => {
									const result: any = {
										currency: finData.currency,
										documentDate: intToDate(finData.documentDate),
										documentNumber: finData.documentNumber,
										dueDate: intToDate(finData.dueDate),
										entryType: finData.entryTypeName,
										entryTypeName: this.entryTypeDisplayName(finData.entryTypeName),
										externalId: finData.externalId,
										currencyAmount: null,
										partnerName: null,
									};
									if (finData.postings && finData.postings.length) {
										result.postings = finData.postings.map((posting: any) => {
											return {
												debitAccount: posting.debitAccount,
												creditAccount: posting.creditAccount,
												employeeName: posting.employeeName,
												employeePIN: posting.employeePIN,
												partnerName: posting.partnerName,
												amount: posting.amount || 0
											}
										});

										result.partnerName = finData.postings[0].partnerName;
										let currencyAmount = 0,
											amount = 0;
										finData.postings.forEach((posting: any) => {
											//AM. 20.01.2023. no task. if removed because there is no journalCode in query
											//if (posting.journalCode != 'OD') {
											if (posting.creditAccount != '4426.neded') {
												currencyAmount += (posting.currencyAmount || 0);
												amount += (posting.amount || 0);
											}
										});
										if (finData.currency == this.configService.localCurrencyCode) {
											result.currencyAmount = amount;
										} else {
											result.currencyAmount = currencyAmount;
										}
									}
									return result;
								});

							}
						}
						return null;
					})
				);
		} else {
			return of(null);
		}
	}

	public accMetadata(file: AccDocumentFileModel): Observable<any> {
		return this.accMetadataStream(file, accMetadataQueryText);
	}

	public accFullMetadata(file: AccDocumentFileModel): Observable<any> {
		return this.accMetadataStream(file, accFullMetadataQueryText);
	}

	public setCostCenter(file: AccDocumentFileModel) {
		return this.restService
			.mutate({
				mutation: setCostCenterMutationText,
				variables: {
					docEid: file.id,
					costCenter: { costCenter: file.costCenter }
				}
			});
	}

	public setEmployee(file: AccDocumentFileModel, employee: PersonForAccountingModel) {
		return this.restService
			.mutate({
				mutation: setEmployeeMutationText,
				variables: {
					docEid: file.id,
					data: { employee: classToPlain(employee, { excludePrefixes: ["__"] })}
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}


	public downloadAccMetadata(file: AccDocumentFileModel) {
		return this.configService
			.getSubsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/findocs/${file.id}/findata/download?attachment=true`;
					return this.downloadService.download(url);
				})
			);
	}

	public updateDueDate(file: AccDocumentFileModel, dueDate: Date) {
		return this.restService
			.mutate({
				mutation: updateDueDateMutationText,
				variables: {
					documentEid: file.id,
					dueDate: dateToInt(dueDate)
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}
}
