import { Observable, Subscription, Subject, BehaviorSubject, combineLatest, timer, merge, of, } from 'rxjs';
import { filter, map, switchMap, finalize, retryWhen, delayWhen, debounceTime, startWith, } from 'rxjs/operators';
import gql from 'graphql-tag';

import { SpinnerService, UID, dateToInt, intToDate, } from '@saliente/library';

import { AuthService, } from '../auth/auth.service';
import { ConfigService, } from '../general/config.service';
import { DownloadService, } from '../general/download.service';
import { RestService, } from '../general/rest.service';
import { UploadService, } from '../general/upload.service';
import { DocumentsBaseService } from './documents-base.service';

import { DocumentPageModel, } from './documents-base.models';
import { DocumentFileModel, DocumentCommentModel, PersonForAccountingModel } from './documents.models';
import { DocumentFileStatus, FinancialDocumentEntryType, } from './documents.enums';
import { classToPlain, plainToClass } from 'class-transformer';


const documentsStatisticsQueryText = gql`
query documentsStatistics($clientEid: String!){
	documents{
		GetClientDocumentsTotalStatisticsUsingGET(clientEid:$clientEid){
			bookedCounter
			cancelledCounter
			deletedCounter
			digitizedCounter
			invalidCounter
			markedForCancellationCounter
			markedForDeletionCounter
			notUsableCounter
			queuedForDigitizationCounter
			queuedForVerificationCounter
			receivedCounter
			sentToExpertCounter
			unavailableCounter
			verifiedCounter
		}
	}
}`;

const deleteDocumentMutationText = gql`
mutation deleteDocument($docEid: String!, $commentData:documents_DocumentCommentDataInput!){
  documents {
    DeleteDocumentUsingDELETE(docEid:$docEid, commentData:$commentData) {
      Message # empty
    }
  }
}`;

const unblurDocumentMutationText = gql`
mutation unblurDocument($data:documents_MarkUsableDataInput!){
  documents {
    MarkUsableUsingPOST(markData:$data) {
      Message # empty
    }
  }
}`;

const addDocumentCommentMutationText = gql`
mutation addDocumentComment($docEid: String!, $commentData: documents_DocumentCommentDataInput){
  documents {
    AddCommentUsingPOST(docEid:$docEid, commentData: $commentData) {
      Message # empty
    }
  }
}`;

const requestDocumentsArchiveMutationText = gql`
mutation requestDocumentsArchive($clientEid: String!, $dateStart: Int!, $dateEnd: Int!, $downloadType: String!){
  documents {
    requestAllDocumentsUsingPOST(clientEid:$clientEid, dateStart: $dateStart, dateEnd: $dateEnd, downloadType: $downloadType) {
			Message
    }
  }
}`;

const addChatMessageMutationText = gql`
mutation addChatMessage($clientExternalId: String!, $chatTypeExternalId: String!, $message: notifications_ChatMessageRepresentationInput!) {
	notifications {
		addChatMessageUsingPOST(clientExternalId: $clientExternalId, chatTypeExternalId: $chatTypeExternalId, message: $message) {
			Message # empty
		}
	}
}
`;

const ignoreEFacturaByDocumentEidMutationText = gql`
mutation ignoreEFacturaByDocumentEid($clientEid: String!, $data: accounts_EFacturaInboxIgnoreRepresentationInput!) {
	accounts {
		ignoreEFacturaByDocumentEidUsingPOST(clientEid: $clientEid, data: $data) {
			Message
		}
	}
}
`;

const ignoreEFacturaOutboxByDocumentEidMutationText = gql`
mutation ignoreEFacturaOutboxByDocumentEid($clientEid: String!, $data: accounts_EFacturaInboxIgnoreRepresentationInput!) {
	accounts {
		ignoreEFacturaOutboxByDocumentEidUsingPOST(clientEid: $clientEid, data: $data) {
			Message
		}
	}
}
`;

export interface IDocumentsService {
	filters: DocumentsFilters;
	statistics: any;

	model: DocumentFileModel[];
	fetchList(refresh: boolean): Observable<DocumentFileModel[]>;
	fetchMore(): Promise<boolean>

	documentStatusName(statusCode: string, plural: boolean): string;
	overlayClass(statusCode: string): string;
	extendedOverlayClass(statusCode: string): string;

	downloadDocument(file: DocumentFileModel, page?: DocumentPageModel): Observable<any>;
	downloadOriginalDocument(file: DocumentFileModel, page?: DocumentPageModel): Observable<any>;
	downloadFullDocument(file: DocumentFileModel): Observable<any>;
	uploadDocument(file: DocumentFileModel, documentEid?: string): Observable<any>;
	getDocumentSrc(file: DocumentFileModel, page?: DocumentPageModel): string;
	getOriginalDocumentSrc(file: DocumentFileModel, page?: DocumentPageModel): string;
	getDocumentThumbnailSrc(file: DocumentFileModel): string;
	getDocumentThumbnailExtSrc(ext: string): string;

	deleteDocument(file: DocumentFileModel, commentText: string): Observable<any>;
	unblurDocument(file: DocumentFileModel, commentText: string): Observable<any>;
	addDocumentCommentStream(file: DocumentFileModel, commentText: string): Observable<any>;
	addChatMessage(file: DocumentFileModel, chatTypeExternalId: string, messageContent: string): Observable<boolean>;
	ignoreEFacturaByDocumentEid(file: DocumentFileModel): Observable<boolean>;
	ignoreEFacturaOutboxByDocumentEid(file: DocumentFileModel): Observable<boolean>;

	financialDocumentEntryTypeFilterList(): Observable<any[]>;
}

export interface DocumentsFiltersValues {
	status?: string,
	keyword?: string,
	entryTypeName?: string,
	minDocumentValue?: number,
	maxDocumentValue?: number,
	minDocumentDate?: Date,
	maxDocumentDate?: Date,
	reference?: string,
}

export class DocumentsFilters {
	public filterType: string = 'keyword';

	private statusStream = new Subject<string>();
	protected _status: string;
	get status() {
		return this._status;
	}
	set status(value: string) {
		this._status = value;
		this.statusStream.next(this._status);
	}

	private keywordStream = new Subject<string>();
	private _keyword: string;
	get keyword() {
		return this._keyword;
	}
	set keyword(value: string) {
		this._keyword = value;
		this.keywordStream.next(this._keyword);
	}

	private entryTypeNameStream = new Subject<string>();
	private _entryTypeName: string = '';
	get entryTypeName() {
		return this._entryTypeName;
	}
	set entryTypeName(value: string) {
		this._entryTypeName = value;
		this.entryTypeNameStream.next(this._entryTypeName);
	}

	private minDocumentValueStream = new Subject<number>();
	private _minDocumentValue: number;
	get minDocumentValue() {
		return this._minDocumentValue;
	}
	set minDocumentValue(value: number) {
		this._minDocumentValue = value;
		this.minDocumentValueStream.next(this._minDocumentValue);
	}

	private maxDocumentValueStream = new Subject<number>();
	private _maxDocumentValue: number;
	get maxDocumentValue() {
		return this._maxDocumentValue;
	}
	set maxDocumentValue(value: number) {
		this._maxDocumentValue = value;
		this.maxDocumentValueStream.next(this._maxDocumentValue);
	}


	private minDocumentDateStream = new Subject<Date>();
	private _minDocumentDate: Date;
	get minDocumentDate() {
		return this._minDocumentDate;
	}
	set minDocumentDate(value: Date) {
		this._minDocumentDate = value;
		this.minDocumentDateStream.next(this._minDocumentDate);
	}

	private maxDocumentDateStream = new Subject<Date>();
	private _maxDocumentDate: Date;
	get maxDocumentDate() {
		return this._maxDocumentDate;
	}
	set maxDocumentDate(value: Date) {
		this._maxDocumentDate = value;
		this.maxDocumentDateStream.next(this._maxDocumentDate);
	}


	private referenceStream = new Subject<string>();
	private _reference: string = '';
	get reference() {
		return this._reference;
	}
	set reference(value: string) {
		this._reference = value;
		this.referenceStream.next(this._reference);
	}


	get keywordAndReference() {
		return this.keyword;
	}
	set keywordAndReference(value: string) {
		this.keyword = value;
		this.reference = value;
	}


	private _stream: Observable<string>;
	get stream() {
		return this._stream;
	}

	get value() {
		return this.buildFilter(this.getFilterData());
	}

	constructor() {
		const startValue = this.value;
		this._stream = merge(
			this.statusStream,
			this.keywordStream,
			this.entryTypeNameStream,
			this.minDocumentValueStream,
			this.maxDocumentValueStream,
			this.minDocumentDateStream,
			this.maxDocumentDateStream,
			this.referenceStream
		).pipe(
			startWith(startValue),
			debounceTime(500),
			map(() => {
				return this.getFilterData();
			}),
			map((filtersValues: DocumentsFiltersValues) => {
				return this.buildFilter(filtersValues);
			})
		);
		this.keyword = null;
		this.minDocumentValue = null;
		this.maxDocumentValue = null;
		this.minDocumentDate = null;
		this.maxDocumentValue = null;
		this.reference = null;
	}

	private getFilterData() {
		return {
			status: this._status,
			keyword: this._keyword,
			entryTypeName: this._entryTypeName,
			minDocumentValue: this._minDocumentValue,
			maxDocumentValue: this._maxDocumentValue,
			minDocumentDate: this._minDocumentDate,
			maxDocumentDate: this._maxDocumentDate,
			reference: this._reference,
		}
	}

	protected getStatusFilter(status: string) {
		return 'status:' + status.trim();
	}

	private buildFilter(filtersValues: DocumentsFiltersValues) {
		let filter = "";
		let separator = "";

		if (filtersValues.status) {
			filter += separator + this.getStatusFilter(filtersValues.status);
			separator = ' AND ';
		}
		if (filtersValues.entryTypeName) {
			filter += separator + 'entryTypeName:' + filtersValues.entryTypeName.trim();
			separator = ' AND ';
		}

		switch (this.filterType) {
			case 'keyword':
				if (filtersValues.keyword) {
					filter += separator + 'text:' + filtersValues.keyword.trim();
					separator = ' AND ';
				}
				break;
			case 'value':
				let minValue = filtersValues.minDocumentValue || filtersValues.minDocumentValue == 0 ? String(filtersValues.minDocumentValue) : '';
				let maxValue = filtersValues.maxDocumentValue || filtersValues.maxDocumentValue == 0 ? String(filtersValues.maxDocumentValue) : '';
				if (minValue || maxValue) {
					filter += separator + `value:(${minValue}, ${maxValue})`;
					separator = ' AND ';
				}
				break;
			case 'date':
				let minDate = filtersValues.minDocumentDate ? dateToInt(filtersValues.minDocumentDate) : '';
				let maxDate = filtersValues.maxDocumentDate ? dateToInt(filtersValues.maxDocumentDate) : '';
				if (minDate || maxDate) {
					filter += separator + `finDataDate:(${minDate}, ${maxDate})`;
					separator = ' AND ';
				}
				break;
			case 'reference':
				if (filtersValues.reference) {
					filter += separator + 'reference:' + filtersValues.reference.trim();
					separator = ' AND ';
				}
				break;
		}
		return filter;
	}
}

export abstract class DocumentsService<DocumentFileType
	extends DocumentFileModel, Page extends DocumentPageModel> extends DocumentsBaseService<DocumentFileType, Page>
	implements IDocumentsService {

	public pageSize: number = 200;

	public model: DocumentFileType[];
	public statistics: any = {};

	private _filters: DocumentsFilters;
	private filtersValue: string;
	get filters() {
		return this._filters;
	}

	private sortBy: string = 'documentDate DESC';
	protected sortByStream: BehaviorSubject<string>;

	private documentsQueryStream: Observable<DocumentFileType[]>;
	protected documentsStream: BehaviorSubject<DocumentFileType[]>;
	private documentsSubscription: Subscription;

	public _currentListModel: DocumentFileModel[];
	public get currentListModel() {
		return this._currentListModel;
	}

	constructor(configService: ConfigService, protected restService: RestService, protected authService: AuthService, downloadService: DownloadService, protected uploadService: UploadService, protected spinner: SpinnerService,) {
		super(configService, downloadService);
		this._filters = this.createFilters();
		this.filtersValue = this._filters.value;

		this.sortByStream = new BehaviorSubject(this.sortBy);

		this.documentsStream = new BehaviorSubject<DocumentFileType[]>(null);
		this.documentsQueryStream = combineLatest(this.authService.getSelectedCompanyId(), this._filters.stream, this.sortByStream)
			.pipe(
				switchMap(([companyId, filter, sortBy]) => {
					this.filtersValue = filter;
					return this.documentsQuery({ companyId, filter, sortBy });
				}),
				map((model: DocumentFileType[]) => {
					this.model = model;
					return model;
				})
			);
		this._filters.keyword = "";
	}

	protected createFilters() {
		return new DocumentsFilters();
	}

	protected abstract getDocumentsCategory(): string;
	protected abstract getDocumentsQueryText(): string;
	protected abstract getDocumentsQueryResponse(response: any): any;
	protected abstract getDocumentQueryText(): string;
	protected abstract getDocumentQueryResponse(response: any): any;

	protected abstract plainToDocument(data: any): DocumentFileType;
	protected abstract plainToDocumentArray(data: any[]): DocumentFileType[];

	public financialDocumentEntryTypeFilterList(): Observable<any[]> {
		return of([
			{ id: 'None', code: 'NECOMPLETAT', name: 'Necompletat' },
			{ id: '', code: 'TOATE', name: 'Toate' },
			{ id: FinancialDocumentEntryType.SaleInvoice, code: 'FV', name: this.entryTypeDisplayName(FinancialDocumentEntryType.SaleInvoice)},
			{ id: FinancialDocumentEntryType.SaleFiscalReceipt, code: 'BFV', name: this.entryTypeDisplayName(FinancialDocumentEntryType.SaleFiscalReceipt)},
			{ id: FinancialDocumentEntryType.PurchaseInvoice, code: 'FA', name: this.entryTypeDisplayName(FinancialDocumentEntryType.PurchaseInvoice)},
			{ id: FinancialDocumentEntryType.AutoInvoice, code: 'FAAU', name: this.entryTypeDisplayName(FinancialDocumentEntryType.AutoInvoice)},
			{ id: FinancialDocumentEntryType.PurchaseFiscalReceipt, code: 'BFA', name: this.entryTypeDisplayName(FinancialDocumentEntryType.PurchaseFiscalReceipt)},
			{ id: FinancialDocumentEntryType.PurchaseFiscalReceiptWithoutTIN, code: 'BFAC', name: this.entryTypeDisplayName(FinancialDocumentEntryType.PurchaseFiscalReceiptWithoutTIN)},
			{ id: FinancialDocumentEntryType.GasReceipt, code: 'BCM', name: this.entryTypeDisplayName(FinancialDocumentEntryType.GasReceipt)},
			{ id: FinancialDocumentEntryType.AutoReceipt, code: 'BAU', name: this.entryTypeDisplayName(FinancialDocumentEntryType.AutoReceipt)},
			{ id: FinancialDocumentEntryType.ProtocolFiscalReceipt, code: 'BFP', name: this.entryTypeDisplayName(FinancialDocumentEntryType.ProtocolFiscalReceipt)},
			{ id: FinancialDocumentEntryType.CashReceipt, code: 'CC', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashReceipt)},
			{ id: FinancialDocumentEntryType.CashIssued, code: 'CE', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashIssued)},
			{ id: FinancialDocumentEntryType.CashIssuedSales, code: 'CEFV', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashIssuedSales)},
			{ id: FinancialDocumentEntryType.ExpenseAccountReceipt, code: 'CD', name: this.entryTypeDisplayName(FinancialDocumentEntryType.ExpenseAccountReceipt)},
			{ id: FinancialDocumentEntryType.CashOrder, code: 'DP', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashOrder)},
			{ id: FinancialDocumentEntryType.CollectionOrder, code: 'DI', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CollectionOrder)},
			{ id: FinancialDocumentEntryType.StatementOfAccount, code: 'EC', name: this.entryTypeDisplayName(FinancialDocumentEntryType.StatementOfAccount)},
		]);
	}

	public documentsQuery(variables: { companyId: string, offset?: number, filter?: string, sortBy?: string }): Observable<DocumentFileType[]> {
		let filter = 'category:' + this.getDocumentsCategory();
		if (variables.filter) {
			filter += ' AND ' + variables.filter;
		}
		return this.restService
			.query(
				{
					query: this.getDocumentsQueryText() as any,
					fetchPolicy: 'network-only',
					variables: {
						clientEid: variables.companyId,
						filter: filter,
						sort: variables.sortBy,
						offset: variables.offset || 0,
						count: this.pageSize
					}
				})
			.pipe(
				map((response: any) => {
					this.statistics = {};
					if (response && response.data) {
						const statistics = response.data.documents.GetClientDocumentsStatisticsUsingGET;
						if (statistics && statistics.length) {
							statistics.forEach((s: any) => {
								this.statistics[s.chargeableInterval] = s.chargeableAmount;
							});
						}

						this._currentListModel = this.plainToDocumentArray(this.processDocuments(this.getDocumentsQueryResponse(response)));
						return this.plainToDocumentArray(this.processDocuments(this.getDocumentsQueryResponse(response)));
					}
					return null;
				})
			);
	}

	protected onModelChanged() { }

	public fetchList(refresh: boolean = true): Observable<DocumentFileType[]> {
		if (!this.documentsSubscription || refresh) {
			if (this.documentsSubscription) {
				this.model = null;
				this.documentsStream.next(null);
				this.documentsSubscription.unsubscribe();
			}
			this.documentsSubscription = this.documentsQueryStream.subscribe((model) => {
				this.model = model;
				this.documentsStream.next(model);
				this.onModelChanged();
			});
		}
		return this.documentsStream;
	}

	public fetchMoreBase() {
		return this
			.documentsQuery(
				{
					companyId: this.authService.user.selectedCompanyId,
					offset: this.model.length,
					filter: this.filtersValue
				})
			.pipe(
				map((documents) => {
					if (documents) {
						const documentsLength = documents.length;
						let result = false;
						for (let documentIndex = 0; documentIndex < documentsLength; documentIndex++) {
							const document = documents[documentIndex];
							var item = this.model.find((item: DocumentFileType) => {
								return item.id === document.id;
							});
							if (item) {
								item.copy(document);
							} else {
								this.model.push(document);
								result = true;
							}
						}
						this.documentsStream.next(this.model);
						this._currentListModel = this.model;
						return result;
					}
					return false;
				})
			);
	}

	public fetchMore() {
		return this.fetchMoreBase().toPromise();
	}

	private refreshDocumentsStatisticsStream = new BehaviorSubject(0);
	public documentsStatisticsQueryStream(): Observable<any> {
		return combineLatest([this.authService.user.selectedCompanyIdObservable, this.refreshDocumentsStatisticsStream])
			.pipe(
				switchMap(([companyId]) => {
					return this.restService
						.query({
							query: documentsStatisticsQueryText,
							fetchPolicy: 'network-only',
							variables: {
								clientEid: companyId
							}
						}, { spinner: false })
						.pipe(
							map((response: any) => {
								if (response) {
									const detailed = response.data.documents.GetClientDocumentsTotalStatisticsUsingGET;
									return {
										notUsable: detailed.notUsableCounter,
										unBooked: detailed.sentToExpertCounter +
											detailed.digitizedCounter +
											detailed.markedForCancellationCounter +
											detailed.markedForDeletionCounter +
											detailed.notUsableCounter +
											detailed.queuedForDigitizationCounter +
											detailed.queuedForVerificationCounter +
											detailed.receivedCounter +
											detailed.sentToExpertCounter +
											detailed.verifiedCounter
									};
								}
								return {
									notUsable: 0,
									unBooked: 0,
								};
							})
						);
				})
			);
	}

	public refreshDocumentsStatistics() {
		this.refreshDocumentsStatisticsStream.next(this.refreshDocumentsStatisticsStream.value + 1);
	}

	public uploadDocument(file: any, documentEid?: string) {
		const replacedDocumentEid = documentEid;
		const options: any = {
			clientEid: this.authService.user.selectedCompanyId,
			documentType: 'SCAN',
			category: this.getDocumentsCategory()
		};
		if (documentEid) {
			options.supersedesDocumentEid = documentEid;
		}
		documentEid = options.documentEid = UID();

		this.spinner.show();
		return this.configService
			.getDocumentsUploadUrl()
			.pipe(
				switchMap((url) => {
					return this.uploadService.upload(url + '/files', file, options);
				}),
				filter((data) => !!data.done),
				switchMap((data) => {
					return this.restService
						.query(
							{
								query: this.getDocumentQueryText() as any,
								fetchPolicy: 'network-only',
								variables: {
									docEid: documentEid
								}
							}, { spinner: false, error: false })
						.pipe(
							map((response: any) => {
								const data = [this.getDocumentQueryResponse(response)];
								const fetchedModel = this.plainToDocument(this.processDocuments(data));
								const args = [0, 0];
								args.push.apply(args, fetchedModel);
								this.model.splice.apply(this.model, args);

								if (replacedDocumentEid) {
									const replacedDocumentIndex = this.model.findIndex((document) => document.id === replacedDocumentEid);
									if (replacedDocumentIndex !== -1) {
										this.model.splice(replacedDocumentIndex, 1);
									}
								}
								this.documentsStream.next(this.model);
								return response;
							}),
							retryWhen(errors => errors.pipe(delayWhen(val => timer(1000))))
						);
				}),
				finalize(() => {
					this.refreshDocumentsStatistics();
					this.spinner.hide();
				})
			);
	}

	public deleteDocument(file: DocumentFileType, commentText: string): Observable<any> {
		const documentEid = file.id;
		return this.restService
			.mutate(
				{
					mutation: deleteDocumentMutationText,
					variables: {
						docEid: documentEid,
						commentData: { comment: commentText }
					}
				})
			.pipe(
				map((response) => {
					if (response) {
						if (this.model) { // AM. 05.03.2025. task 10106: this.model exists when delete is done from the documents list; when it is done from somewhere else (chat) it is undefined
							const deletedDocumentIndex = this.model.findIndex((document) => document.id === documentEid);
							if (deletedDocumentIndex !== -1) {
								this.model.splice(deletedDocumentIndex, 1);
							}
						}
					}
					return response;
				})
			);
	}

	public unblurDocument(file: DocumentFileType, commentText: string): Observable<any> {
		const documentEid = file.id;
		return this.restService
			.mutate(
				{
					mutation: unblurDocumentMutationText,
					variables: {
						data: { documentEid: documentEid, comment: commentText }
					}
				})
			.pipe(
				map((response) => {
					if (response) {
						const unbluredDocument = this.model.find((document) => document.id === documentEid);
						if (unbluredDocument) {
							unbluredDocument.status.statusCode = DocumentFileStatus.Received;
						}
						this.refreshDocumentsStatistics();
					}
					return response;
				})
			);
	}
	
	public addDocumentCommentStream(file: DocumentFileType, commentText: string): Observable<any> {
		const documentEid = file.id;
		return this.restService
			.mutate(
				{
					mutation: addDocumentCommentMutationText,
					variables: {
						docEid: documentEid,
						commentData: { comment: commentText }
					}
				})
			.pipe(
				map((response) => {
					const saved = !!response;
					if (saved) {
						const commentModel = new DocumentCommentModel();
						commentModel.comment = commentText;
						commentModel.dateTime = new Date();
						file.comments = file.comments || [];
						file.comments.splice(0, 0, commentModel);
					}
					return saved;
				})
			);
	}

	public downloadAllDocuments(yearMonth: number, downloadType: string = 'Accounting') {
		const self = this;
		return self.authService
			.getSelectedCompanyId()
			.pipe(
				switchMap((clientEid: string) => {
					const dateStart = yearMonth * 100 + 1;
					let dateEnd: any = intToDate(dateStart);
					dateEnd.setMonth(dateEnd.getMonth() + 1);
					dateEnd.setDate(dateEnd.getDate() - 1);
					dateEnd = dateToInt(dateEnd);

					return this.restService
						.mutate({
							mutation: requestDocumentsArchiveMutationText,
							variables: {
								clientEid,
								dateStart,
								dateEnd,
								downloadType
							}
						})
						.pipe(
							map((response) => {
								return !!response;
							})
						);
				})
			);
	}

	public addChatMessage(document: DocumentFileModel, chatTypeExternalId: string, messageContent: string): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: addChatMessageMutationText,
				variables: {
					clientExternalId: this.authService.user.selectedCompanyId,
					chatTypeExternalId: chatTypeExternalId,
					message: { content: messageContent, source: 'web' }
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public ignoreEFacturaByDocumentEid(document: DocumentFileModel): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: ignoreEFacturaByDocumentEidMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					data: { externalId: document.id }
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						const deletedDocumentIndex = this.model.findIndex((doc) => doc.id === document.id);
						if (deletedDocumentIndex !== -1) {
							this.model.splice(deletedDocumentIndex, 1);
						}
						return true;
					}
					return false;
				})
			);
	}

	public ignoreEFacturaOutboxByDocumentEid(document: DocumentFileModel): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: ignoreEFacturaOutboxByDocumentEidMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					data: { externalId: document.id }
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						const deletedDocumentIndex = this.model.findIndex((doc) => doc.id === document.id);
						if (deletedDocumentIndex !== -1) {
							this.model.splice(deletedDocumentIndex, 1);
						}
						return true;
					}
					return false;
				})
			);
	}
}
